import React from 'react';
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from "components/Navbars/ITCreativeNav";

import ChatBanner from 'components/Software/ChatBanner';
import Footer from 'components/Saas/Footer';
import { Link } from 'gatsby';
import Blog3 from 'components/Software/Blog3';
import Codei from 'components/Preview/Codei';



const IppisLoan = () => {
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#673ab7'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Get your funds in less than   <span> 10 minutes!</span></h1>
                <p className="p">Enjoy quick and easy access to funds faster than you can blink.</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">     
        
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" /> 
        <img src="/assets/img/about/IPPIS LOAN.png" alt="" className='img-body' />      
      </div>
    </header>
      <main>
      <section className="about section-padding style-3" data-scroll-index="3">
      
        <div className="container">
          <div className="row gx-0 justify-content-end">
            <div className="col-lg-6">
            <div className="img">
          <img src="/assets/img/about/IPPIS LOAN.png" alt="" className='img-body' />          
        </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <div className="section-head long-shape mb-40 style-3">
                  <h3>IPPIS <span> Loan</span></h3>
                </div>
                <p>
                Are you a government worker? Our IPPIS Loan offers special terms for public sector employees, giving you fast access to funds with minimal paperwork. Enjoy low-interest rates and flexible repayment plans tailored to suit your salary structure. Apply today and secure the financial support you need.
                </p>
               
                <div className="text mb-20">
                  <h5>Requirements: </h5>
                  <ul>
                    <li>BVN</li>
                    <li>Federal Civil Servant</li>
                    <li>Enrolled with IPPIS</li>
                    <li>A Good Netpay</li>                    
                                      
                  </ul>
                </div>
               
                <a href="https://forms.zohopublic.com/MutuaTrustMFB/form/PUBLICSECTORLOANAPPLICATIONFORM2/formperma/qt6twdH1v0w5M8zBnWX8iSwNOgvo0oILNVNsEA2gQEY" className="btn rounded-pill bg-blue2 sm-butn text-white mt-10">
                  <span>Apply for IPPIS Loan</span>
                </a>
              </div>
            </div>
          </div>
        </div>
    
    </section>
       
        <Blog3 />
        <Codei /> 
        <ChatBanner />
       
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
     <title> Fast IPPIS Loans in Nigeria - Apply and Get Your Money Now.</title>
<meta name="description" content="Are you looking for fast IPPIS loans in Nigeria to settle your financial needs? Apply for your loan now and receive your cash in 10 hours or less." /> 

      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default IppisLoan;